// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-jsx": () => import("./../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-journal-jsx": () => import("./../../src/templates/journal.jsx" /* webpackChunkName: "component---src-templates-journal-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-tags-jsx": () => import("./../../src/templates/tags.jsx" /* webpackChunkName: "component---src-templates-tags-jsx" */)
}

